import Vue from 'vue'
import App from './App.vue'
import router from '@/router/news'
import global from '@/utlis/global.js'
import store from '@/store'
import Meta from 'vue-meta'
import '@/utlis/remcommon.js'
import VueLazyload from 'vue-lazyload'
import { initializeApp} from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
Vue.config.productionTip = false
Vue.prototype.$global = global
const loadimage = require('@/assets/news/loading.png')
Vue.prototype.$api = global.api
import '../../utlis/vant'
Vue.use(Meta)
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: loadimage,
	loading: loadimage,
	attempt: 1,
})

const firebaseConfig = {
  apiKey: "AIzaSyBaGW-0Gbmz5sXsxz1x0GMJIHc5_St6wb4",
  authDomain: "newss-pro.firebaseapp.com",
  projectId: "newss-pro",
  storageBucket: "newss-pro.appspot.com",
  messagingSenderId: "508074824106",
  appId: "1:508074824106:web:120c04f7437dd04ffa9bd3",
  measurementId: "G-DEFWGZC6Y2"
};  

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
Vue.prototype.$logEvent = (event, params) => {
  logEvent(analytics, event, params);
};
Vue.prototype.$eventrackFb = (msg, method,map = {}) => {
  let params = {
    time: new Date(),
    message: msg,
     method: method,
    ...map,
  };
  logEvent(analytics, msg, params);
};
new Vue({
  router,store,
  render: h => h(App),
}).$mount('#app')
